import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// 正常加载
// import Home from '../views/home'

// 按需（懒）加载（vue实现）

const Home = () => import('views/mobile/home')
const HomePage = () => import('views/mobile/home/HomePage.vue')
const AboutOS = () => import('views/mobile/home/AboutOS.vue')
const MyAsk = () => import('views/mobile/home/MyAsk.vue')
const AboutUS = () => import('views/mobile/home/AboutUS.vue')
const Feedback = () => import('views/mobile/home/Feedback.vue')
const ContactUS = () => import('views/mobile/home/ContactUS.vue')
const Privacy = () => import('views/mobile/home/Privacy.vue')
const Disclaimer = () => import('views/mobile/home/Disclaimer.vue')

const Login = () => import('views/mobile/Login.vue')
const Register = () => import('views/mobile/Register.vue')
const ForgetPassword = () => import('views/mobile/ForgetPassword.vue')

const User = () => import('views/mobile/user')

const UserAqNews = () => import('views/mobile/user/aq-news')
const AddNews = () => import('views/mobile/user/aq-news/AddNews.vue')
const MyQuestion = () => import('views/mobile/user/aq-news/MyQuestion.vue')
const MyNews = () => import('views/mobile/user/aq-news/MyNews.vue')
const MySaved = () => import('views/mobile/user/aq-news/MySaved.vue')

const UserAssociation = () => import('views/mobile/user/association')
const MyActivity = () => import('views/mobile/user/association/MyActivity.vue')

const UserBooking = () => import('views/mobile/user/booking')
const BookingOrder = () => import('views/mobile/user/booking/BookingOrder.vue')
const Record = () => import('views/mobile/user/booking/Record.vue')
const RecordDetail = () => import('views/mobile/user/booking/RecordDetail.vue')

const Card = () => import('views/mobile/user/card')
const PlanCard = () => import('views/mobile/user/card/PlanCard.vue')
const ServiceCard = () => import('views/mobile/user/card/ServiceCard.vue')

const UserHome = () => import('views/mobile/user/user-info')
const UserInfo = () => import('views/mobile/user/user-info/UserInfo.vue')
const UpdatePassword = () => import('views/mobile/user/user-info/UpdatePassword.vue')

const Doctor = () => import('views/mobile/doctor')
const DoctorClinic = () => import('views/mobile/doctor/DoctorClinic.vue')
const DoctorAsk = () => import('views/mobile/doctor/DoctorAsk.vue')
const DoctorNews = () => import('views/mobile/doctor/DoctorNews.vue')

const Booking = () => import('views/mobile/booking')
const DoctorList = () => import('views/mobile/booking/DoctorList.vue')
const BookingEdit = () => import('views/mobile/booking/BookingEdit.vue')

const ResumeCenter = () => import('views/mobile/user/resume')
const ApplyJobs = () => import('views/mobile/user/resume/ApplyJobs.vue')

const Clinic = () => import('views/mobile/clinic')
const ClinicList = () => import('views/mobile/clinic/ClinicList.vue')
const ClinicDetail = () => import('views/mobile/clinic/ClinicDetail.vue')

const News = () => import('views/mobile/news')
const NewsList = () => import('views/mobile/news/NewsList.vue')
const NewsDetail = () => import('views/mobile/news/NewsDetail.vue')

const Ask = () => import('views/mobile/ask')
const AddAsk = () => import('views/mobile/ask/AddAsk.vue')
const AskList = () => import('views/mobile/ask/AskList.vue')
const AskDetail = () => import('views/mobile/ask/AskDetail.vue')

const Children = () => import('views/mobile/children')
const ChildrenBMI = () => import('views/mobile/children/ChildrenBMI.vue')
const ChildrenChest = () => import('views/mobile/children/ChildrenChest.vue')
const ChildrenHead = () => import('views/mobile/children/ChildrenHead.vue')
const ChildrenHeight = () => import('views/mobile/children/ChildrenHeight.vue')
const ChildrenWeight = () => import('views/mobile/children/ChildrenWeight.vue')

const Association = () => import('views/mobile/association')

const OSRegister = () => import('views/mobile/os/OSRegister.vue')

const PageNotFound = () => import('views/mobile/PageNotFound')

const AppDownload = () => import('views/mobile/AppDownload')

const JobList = () => import('views/mobile/job/JobList')
const JobDetail = () => import('views/mobile/job/JobDetail')

const AppPrivacy = () => import('views/appPrivacy')

const UserPrivacy = () => import('views/mobile/app/userPrivacy')
const UserService = () => import('views/mobile/app/userService')

Vue.use(Router)

let base = `${process.env.BASE_URL}` // 动态获取二级目录

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '*',
      component: PageNotFound,
    },
    {
      path: '/AppDownload',
      component: AppDownload
    },
    {
      path: '/appPrivacy',
      component: AppPrivacy
    },
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '', 
          component: HomePage,
          meta: {
            title: '香港中醫在綫',
            content:{
              keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/news',
          component: News,
          redirect: '/news/list',
          children: [
            {
              path: '/news/list',
              component: NewsList,
              meta: {
                title: '健康資訊 — 香港中醫在綫',
                content:{
                  keywords:'健康資訊,香港中醫在綫,健康問題早知道,中醫新聞',
                  description:'香港中醫在綫’提供免費中醫資訊、中醫知識、中醫健康知識在綫學習',
                }
              }
            }
          ]
        },
        {
          path: '/about-os',
          component: AboutOS,
          meta: {
            title: '关于我们 — 香港中醫在綫',
            content:{
              keywords:'关于我们,关于中醫在綫,香港中醫在綫',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/my-ask',
          component: MyAsk,
          meta: {
            title: '我的問答 — 香港中醫在綫',
            content:{
              keywords:'我的問答,中醫在綫,香港中醫在綫',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        }
      ]
    },
    {
      path: '/news/detail/:newsId',
      component: NewsDetail,
      meta: {
        title: '健康資訊詳情 — 香港中醫在綫',
        content:{
          keywords:'健康資訊,香港中醫在綫,健康問題早知道,中醫新聞',
          description:'香港中醫在綫’提供免費中醫資訊、中醫知識、中醫健康知識在綫學習',
        }
      }
    },
    {
      path: '/news/details/:newsId',
      component: NewsDetail,
      meta: {
        title: '健康資訊詳情 — 香港中醫在綫',
        content:{
          keywords:'健康資訊,香港中醫在綫,健康問題早知道,中醫新聞',
          description:'香港中醫在綫’提供免費中醫資訊、中醫知識、中醫健康知識在綫學習',
        }
      }
    },
    { 
      path: '/login', 
      component: Login,
      meta: {
        title: '用戶登錄 - 香港中醫在綫',
        content:{
          keywords:'用戶登錄,香港中醫在綫,香港中醫在綫網',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    { 
      path: '/register', 
      component: Register,
      meta: {
        title: '用戶註冊 - 香港中醫在綫',
        content:{
          keywords:'用戶註冊,香港中醫在綫,香港中醫在綫網',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    { 
      path: '/forgetPassword', 
      component: ForgetPassword,
      meta: {
        title: '找回密碼 — 香港中醫在綫',
        content:{
          keywords:'找回密碼,香港中醫在綫,香港中醫在綫網',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    {
      path: '/doctor',
      component: Booking,
      redirect: '/doctor/list',
      children: [
        {
          path: '/doctor/list',
          component: DoctorList,
          meta: {
            title: '預約掛號 - 香港中醫在綫',
            content:{
              keywords:'預約掛號,香港看病掛號,香港中醫在綫,中醫掛號',
              description:'香港中醫在綫提供的在綫掛號中醫師',
            }
          }
        },
        {
          name: 'bookingEdit',
          path: '/doctor/booking',
          component: BookingEdit,
          meta: {
            title: '預約掛號 - 香港中醫在綫',
            content:{
              keywords:'預約掛號,香港看病掛號,香港中醫在綫,中醫掛號',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        }
      ]
    },
    {
      path: '/doctor/:doctorId',
      component: Doctor,
      children: [
        {
          path: '',
          component: DoctorClinic,
          meta: {
            title: '香港中醫師 - 詳細資料 - 香港中醫在綫',
            content:{
              keywords:'預約掛號,香港看病掛號,香港中醫在綫,中醫掛號',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/doctor/:doctorId/ask',
          component: DoctorAsk,
          meta: {
            title: '香港中醫師 - 咨詢問答 - 香港中醫在綫',
            content:{
              keywords:'預約掛號,香港看病掛號,香港中醫在綫,中醫掛號',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          },
        },
        {
          path: '/doctor/:doctorId/news',
          component: DoctorNews,
          meta: {
            title: '香港中醫師 - 健康資訊 - 香港中醫在綫',
            content:{
              keywords:'預約掛號,香港看病掛號,香港中醫在綫,中醫掛號',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        }
      ]
    },
    {
      path: '/hospital',
      redirect: '/hospital/list',
      component: Clinic,
      children: [
        {
          path: '/hospital/list',
          component: ClinicList,
          meta: {
            title: '診所列表 - 香港中醫在綫',
            content:{
              keywords:'香港中醫診所,好診所,診所列表,診所看病',
              description:'中醫在綫所有的診所列表，提供在綫咨詢診所醫師和掛號服務',
            }
          }
        },
        {
          path: '/hospital/detail/:clinicId',
          component: ClinicDetail,
          meta: {
            title: '香港中醫在綫',
            content:{
              keywords:'香港中醫診所,好診所,診所列表,診所看病',
              description:'中醫在綫所有的診所列表，提供在綫咨詢診所醫師和掛號服務',
            }
          }
        }
      ]
    },
    {
      path: '/children',
      component: Children,
      meta: {
        title: '兒童健康 - 香港中醫在綫',
        content:{
          keywords:'兒童健康,兒童健康知識,香港兒童健康,育兒知識',
          description:'生長發育是嬰幼兒及青少年期所特有的生理現象。但生長發育不是簡單的身體由小增大的過程，而是一個十分複雜的現象。 牽涉到個體細胞的增加、分化，器官結構及功能的完善。',
        }
      },
      children: [
        { path: '', component: ChildrenHeight},
        { 
          path: '/children/height', 
          component: ChildrenHeight,
          meta: {
            title: '小孩(兒童)健康身高 - 香港中醫在綫',
            content:{
              keywords:'小孩(兒童)健康身高,兒童健康知識,香港兒童健康,育兒知識',
              description:'生長發育是嬰幼兒及青少年期所特有的生理現象。但生長發育不是簡單的身體由小增大的過程，而是一個十分複雜的現象。 牽涉到個體細胞的增加、分化，器官結構及功能的完善。',
            }
          }
        },
        { 
          path: '/children/weight', 
          component: ChildrenWeight,
          meta: {
            title: '小孩(兒童)健康體重 - 香港中醫在綫',
            content:{
              keywords:'小孩(兒童)健康體重,兒童健康知識,香港兒童健康,育兒知識',
              description:'體重是反映小兒生長發育的最重要也是最靈敏的指標。因為體重反映孩子的營養狀況，尤其是近期的營養狀況。體重可以受多種因素如:營養、輔食添加、疾病等的影響。體重在出生頭3個月增長最快，一般為月增長600～1000克，最好不低於600克。3～6個月次之，一般月增長60 0～800克。6～12個月平均每個月增長300克。1歲後小兒生長速度明顯減慢，1～3歲小兒平均每個月體重增長150克。1歲後小兒的體重可用下列公式計算:體重（kg）=年齡（歲）×2+7（或8）',
            }
          }
        },
        { 
          path: '/children/bmi', 
          component: ChildrenBMI,
          meta: {
            title: '小孩(兒童)健康BMI - 香港中醫在綫',
            content:{
              keywords:'小孩(兒童)健康BMI,兒童健康知識,香港兒童健康,育兒知識',
              description:'兒童BMI也稱為年齡別BMI，因為不同年齡有著不同的體重分級。體重指數(BMI)的計算方法： 體重(公斤)/身高的平方(米的平方)，例如：體重62kg、身高145cm、年齡10歲的男孩，BMI=62/(1.45*1.45)=29.49(kg/m2)，查表：肥胖，應合理飲食加運動控制，禁止饑餓半饑餓療法，原則上不採用藥物和手術療法。',
            }
          }
        },
        { 
          path: '/children/chest_circ', 
          component: ChildrenChest,
          meta: {
            title: '小孩(兒童)健康頭圍 - 香港中醫在綫',
            content:{
              keywords:'小孩(兒童)健康頭圍,兒童健康知識,香港兒童健康,育兒知識',
              description:'頭圍是反映孩子腦發育的一個重要指標。頭圍在生後第一年增長最快。出生時頭圍平均34cm;1歲時平均46cm；第二年增加2cm，第三年增長1～2cm。3歲時頭圍平均為48cm，已與成人相差不很多了。由此可看出，腦發育主要在生後頭3年。正常小兒後囟門3個月閉合，前囟門1歲～1 歲半閉合。過遲閉合要考慮有無佝僂病的可能。有的孩子出生時囟門就較小，閉合也會早些。這與母親孕期營養狀況較好有關。因此要綜合看待這個問題，如果沒有超量服用魚肝油或超量服用及注射維生素D，一般問題不大。 需要注意的是，並非像人們所想像的那樣:孩子頭越大越聰明，聰明與否和頭圍大小並不成正比。孩子的頭圍在正常範圍內就可以了。頭圍過大則要考慮有無腦腫瘤、腦積水的可能。',
            }
          }
        },
        { 
          path: '/children/head_circ', 
          component: ChildrenHead,
          meta: {
            title: '小孩(兒童)健康胸圍 - 香港中醫在綫',
            content:{
              keywords:'小孩(兒童)健康胸圍,兒童健康知識,香港兒童健康,育兒知識',
              description:'胸圍,孩子在出生時，胸圍小於頭圍，隨著月齡的增長，胸圍逐漸趕上頭圍。一般在孩子1歲時，胸圍與頭圍相等。但現在由於普遍營養狀況較好，不少嬰兒在未滿1歲時胸圍就趕上了頭圍。影響胸圍增長的因素有:營養狀況不好，缺乏體育活動及疾病造成胸廓畸形，如:雞胸、漏斗胸等。孩子1歲後，胸圍增長明顯快於頭圍，胸圍逐漸超過頭圍。到青春期胸廓發育很快，向成人體型轉變。',
            }
          }
        }
      ]
    },
    { 
      path: '/association/list', 
      component: Association,
      meta: {
        title: '中醫學會 - 香港中醫在綫',
        content:{
          keywords:'中醫學會,香港中醫學會,學會最新活動,中醫在綫',
          description:'加入中醫學會和參加最新的中醫學會活動',
        }
      }
    },
    { 
      path: '/Activity/', 
      component: Association,
      meta: {
        title: '中醫學會 - 香港中醫在綫',
        content:{
          keywords:'中醫學會,香港中醫學會,學會最新活動,中醫在綫',
          description:'加入中醫學會和參加最新的中醫學會活動',
        }
      }
    },
    {
      path: '/ask',
      component: Ask,
      redirect: '/ask/postnew',
      children: [
        {
          path: '/ask/postnew',
          component: AddAsk,
          meta: {
            title: '免費咨詢 - 在綫求醫 - 香港中醫在綫',
            content:{
              keywords:'咨詢醫師,咨詢中醫師,中醫知識,在綫求醫',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/ask/list',
          component: AskList,
          meta: {
            title: '健康問答 - 在綫求醫 - 香港中醫在綫',
            content:{
              keywords:'健康問答,在綫求醫,中醫知識,中醫問答',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/ask/detail/:id',
          component: AskDetail,
          meta: {
            title: '中醫咨詢詳情 - 在綫求醫 - 香港中醫在綫',
            content:{
              keywords:'在綫問醫,在綫求醫,中醫知識,中醫問答',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        {
          path: '/ask/detail/:id',
          component: AskDetail,
          meta: {
            title: '中醫咨詢詳情 - 在綫求醫 - 香港中醫在綫',
            content:{
              keywords:'在綫問醫,在綫求醫,中醫知識,中醫問答',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        }
      ]
    },
    {
      path: '/user',
      component: User,
      redirect: '/user/home',
      children: [
        { 
          path: '/user/home', 
          component: UserHome,
          meta: {
            title: '個人中心 - 用戶信息',
            content:{
              keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
              description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
            }
          }
        },
        { 
          path: '/user/userInfo', 
          component: UserInfo,
          meta: {
            title: '個人中心 - 用戶信息',
          }
        },
        { 
          path: '/user/updatePwd', 
          component: UpdatePassword,
          meta: {
            title: '個人中心 - 用戶信息',
          }
        },
        {
          path: '/user/booking', 
          component: UserBooking,
          redirect: '/user/booking/order',
          children: [
            {
              path: '/user/booking/order', 
              component: BookingOrder,
              meta: {
                title: '我的预约',
              }
            },
            {
              path: '/user/booking/record', 
              component: Record,
            },
            {
              path: '/user/booking/record/detail/:id', 
              component: RecordDetail,
              meta: {
                title: '處方詳情',
              }
            }
          ]
        },
        {
          path: '/user/card', 
          component: Card,
          redirect: '/user/card/plan',
          children: [
            { 
              path: '/user/card/plan', 
              component: PlanCard,
              meta: {
                title: '個人中心 - 醫療計劃',
              }
            },
            { 
              path: '/user/card/service', 
              component: ServiceCard,
              meta: {
                title: '個人中心 - 服務卡',
              }
            }
          ]
        },
        {
          path: '/user/aq-news', 
          component: UserAqNews,
          redirect: '/user/aq-news/question',
          children: [
            { 
              path: '/user/aq-news/question', 
              component: MyQuestion,
              meta: {
                title: '個人中心 - 我的問答',
              }
            },
            // { 
            //   path: '/user/aq-news/saved', 
            //   component: MySaved,
            //   meta: {
            //     title: '個人中心 - 我的收藏',
            //   }
            // },
            // { 
            //   path: '/user/aq-news/news', 
            //   component: MyNews,
            //   meta: {
            //     title: '個人中心 - 健康資訊',
            //   }
            // },
            // { 
            //   path: '/user/aq-news/addNews', 
            //   component: AddNews,
            //   meta: {
            //     title: '個人中心 - 發佈資訊',
            //   }
            // }
          ]
        },
        {
          path: '/user/resume',
          component: ResumeCenter,
          meta: {
            title: '個人中心 - 我的履歷',
          }
        },
        {
          path: '/user/applyJobs',
          component: ApplyJobs,
          meta: {
            title: '個人中心 - 我的職位申請',
          }
        },
        {
          path: '/user/association', 
          component: UserAssociation,
          redirect: '/user/association/myActivity',
          children: [
            {
              path: '/user/association/myActivity', 
              component: MyActivity,
              meta: {
                title: '個人中心 - 我的活動',
              }
            }
          ]
        }
      ]
    },
    {
      path: '/home/about', 
      component: AboutUS,
      meta: {
        title: '關於我們 - 香港中醫在綫',
        content:{
          keywords:'關於我們,香港中醫在綫,香港中醫在綫網',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    {
      path: '/home/feedback', 
      component: Feedback,
      meta: {
        title: '用戶反饋 - 香港中醫在綫',
        content:{
          keywords:'用戶反饋,香港中醫在綫,香港中醫在綫網',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    {
      path: '/home/contact', 
      component: ContactUS,
      meta: {
        title: '聯絡我們 - 香港中醫在綫',
        content:{
          keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    {
      path: '/home/disclaimer', 
      component: Disclaimer,
      meta: {
        title: '使用條款及免責聲明 - 香港中醫在綫',
        content:{
          keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },
    {
      path: '/home/privacy', 
      component: Privacy,
      meta: {
        title: '私隱政策 - 香港中醫在綫',
        content:{
          keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },{
      path: '/os/register', 
      component: OSRegister,
      meta: {
        title: '中醫師註冊申請 - 香港中醫诊所管理系统 - 香港中醫在綫',
        content:{
          keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },{
      path: '/os/doctor_signup', 
      component: OSRegister,
      meta: {
        title: '中醫師註冊申請 - 香港中醫诊所管理系统 - 香港中醫在綫',
        content:{
          keywords:'香港中醫在綫,中醫系統,香港老中醫,香港中醫院,中醫養生,中醫診所管理軟件,香港名中醫,中醫保健,網上預約掛號,睇醫師,搵醫師,中醫藥諮詢',
          description:'香港中醫在綫網,中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },{ 
      path: '/job/list', 
      component: JobList,
      meta: {
        title: '本地中醫藥職位招聘 - 香港中醫在綫',
        content:{
          keywords:'中醫師招聘,中醫招聘,中醫職位空缺,香港中醫在綫',
          description:'香港中醫在綫網,中醫系統,中醫師招聘,中醫招聘,中醫職位空缺,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },{
      path: '/job/details/:jobId',
      component: JobDetail,
      meta: {
        title: '本地中醫藥職位招聘詳情 — 香港中醫在綫',
        content:{
          keywords:'中醫師招聘,中醫招聘,中醫職位空缺,香港中醫在綫',
          description:'香港中醫在綫網,醫師招聘，中醫系統,中醫診所管理系統,讓更多人認識到中醫,在香港找中醫治病,讓中醫人員對香港作出更大貢獻。',
        }
      }
    },{
      path: '/userService',
      component: UserService,
      meta: {
        title: '用戶服務協議',
        content:{
          keywords:'用戶服務協議,香港中醫在綫',
          description:'大醫APP用戶服務協議',
        }
      }
    },{
      path: '/userPrivacy',
      component: UserPrivacy,
      meta: {
        title: '隱私政策',
        content:{
          keywords:'隱私政策,香港中醫在綫',
          description:'大醫APP隱私政策',
        }
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition
      } else {
          return {
              x: 0,
              y: 0
          }
      }
    }
  })

  function appendMeta(keyword, description) {
    if(!keyword && !description) return;
    
    if(keyword) {
      let keywordMeta = document.querySelector('meta[name="keywords"]')
      if(keywordMeta) {
        document.head.removeChild(keywordMeta)
      }
      keywordMeta = document.createElement('meta')
      keywordMeta.setAttribute('name', 'keywords')
      keywordMeta.setAttribute('content', keyword)
      document.head.appendChild(keywordMeta);
    }
    if(description) {
      let descriptionMeta = document.querySelector('meta[name="description"]')
      if(descriptionMeta) {
        document.head.removeChild(descriptionMeta)
      }
      descriptionMeta = document.createElement('meta')
      descriptionMeta.setAttribute('name', 'description')
      descriptionMeta.setAttribute('content', description)
      document.head.appendChild(descriptionMeta);
    }
  }

  router.beforeEach(async function(to, from, next){
    try {
      if (to.path) {
        if (window._hmt) {
          window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
      }
    } catch(_){
    }

    const canonical = document.querySelector('link[rel*=canonical]');
    const alternate = document.querySelector('link[rel*=alternate]');
    if(canonical) {
      document.head.removeChild(canonical)
    }
    if(alternate) {
      document.head.removeChild(alternate)
    }
    const head = document.head
    
    let pcOrigin = process.env.ENV === 'staging' ? 'https://preview.ectcm.com' : 'https://www.ectcm.com'
    let mobileOrigin = process.env.ENV === 'staging' ? 'https://v3.ectcm.com' : 'https://m.ectcm.com'

    const link = document.createElement("link")
    link.rel = "canonical"
    link.href = `${pcOrigin}${to.fullPath}`
    head.appendChild(link)

    const link2 = document.createElement("link")
    link2.rel = "alternate"
    link2.media = 'only screen and(max-width: 640px)'
    link2.href = `${mobileOrigin}${to.fullPath}`
    head.appendChild(link2)

    // 做些什么，通常权限控制就在这里做哦
    /* 路由发生变化修改页面meta */
    if(to.meta.content) {
      appendMeta(to.meta.content.keywords, to.meta.content.description)
    }
    /* 路由发生变化修改页面title */
    if(to.path == '/appPrivacy') {
      document.title = `大醫`
      next()
    } else if(/^\/doctor\/\d+/.test(to.path)) {
      var _path = to.path.replace('/doctor/', '')
      var index = _path.indexOf('/')
      var globaluserid = _path.substring(0, index == -1 ? _path.length : index)
      //提前獲取醫師信息
      store.dispatch('getDoctorDetail', {
        globaluserid  
      }).then((doctor) => {
          if(doctor) {
            document.title = `香港中醫師${doctor.username} - 詳細資料 - 香港中醫在綫`
            sessionStorage.setItem('detail', JSON.stringify(doctor))
            appendMeta(`${doctor.username},香港中醫師,香港中醫在綫`, '')
            next()
          } else {
            router.push('/doctor/list')
            next(false)
          }
      }).catch((error) => {
        router.push('/doctor/list')
        next(false)
      })
    } else if(/^\/news\/detail\/\d+/.test(to.path) || /^\/news\/details\/\d+/.test(to.path)) {
      var _path
      if(to.path.indexOf('/news/detail/') != -1) {
        _path = to.path.replace('/news/detail/', '')
      } else {
        _path = to.path.replace('/news/details/', '')
      }
      var index = _path.indexOf('/')
      var newsid = _path.substring(0, index == -1 ? _path.length : index)
      //提前獲取資訊詳情
      var clientId = store.getters.clientId || undefined
      store.dispatch('getNewsDetail', { 'newsid': newsid, 'clientid': clientId }).then((news)=>{
        if(news) {
          if(news.title) {
            document.title = `${news.title} - ${news.category || ''} - 香港中醫在綫`
          }
          appendMeta((news.tag||'').split(/\||,|，/).join(','), news.introduce)
          sessionStorage.setItem('detail', JSON.stringify(news))
          next()
        } else {
          router.push('/news/list')
          next(false)
        }
      }).catch((error)=>{
        router.push('/news/list')
        next(false)
      })
    } else if(/^\/ask\/detail\/\d+/.test(to.path)) {
      var _path = to.path.replace('/ask/detail/', '')
      var index = _path.indexOf('/')
      var askid = _path.substring(0, index == -1 ? _path.length : index)
      //提前獲取詳情
      store.dispatch('getAskDetail', {askid: askid}).then((ask)=>{
        if(ask) {
          if(ask.title) {
            document.title = `${ask.title} - ${ask.illnesscategorycode} - 香港中醫在綫`
          }
          appendMeta('', ask.note)
          sessionStorage.setItem('detail', JSON.stringify(ask))
          next()
        } else {
          router.push('/ask/list')
          next(false)
        }
      }).catch((error)=>{
        router.push('/ask/list')
        next(false)
      })
    } else if(/^\/hospital\/detail\/\d+/.test(to.path)) {
      var _path = to.path.replace('/hospital/detail/', '')
      var index = _path.indexOf('/')
      var clinicId = _path.substring(0, index == -1 ? _path.length : index)
      //提前獲取詳情
      store.dispatch('getClinicDetail', {clinicid: clinicId}).then((clinic)=>{
        if(clinic) {
          if(clinic.clinicname) {
            document.title = `${clinic.clinicname} - 香港中醫診所 — 香港中醫在綫`
          }
          appendMeta(`${clinic.clinicname},香港中醫院,香港中醫診所,在綫求醫`, `中醫診所：${clinic.clinicname}，電話：${clinic.contacttel || '--'}，地址：${clinic.address || '--'}`)
          sessionStorage.setItem('detail', JSON.stringify(clinic))
          next()
        } else {
          router.push('/hospital/list')
          next(false)
        }
      }).catch((error)=>{
        router.push('/hospital/list')
        next(false)
      })
    } else if(/^\/job\/details\/\d+/.test(to.path)) {
      var _path = to.path.replace('/job/details/', '')
      var index = _path.indexOf('/')
      var jobId = _path.substring(0, index == -1 ? _path.length : index)
      var clientId = store.getters.clientId || undefined
      const params = {
        'clientId': clientId,
        'jobId': jobId,
        'uid': clientId,
      }
      var jobDetail = await store.dispatch('getJobDetail', params)
      
      if(jobDetail) {
        const employmentTypeList = await store.dispatch('getEmploymentType', {});
        var employmentTypeData = jobDetail.employmenttypeid && employmentTypeList && employmentTypeList.find((v)=>v.codeid == jobDetail.employmenttypeid)
        jobDetail.employmentType = employmentTypeData && employmentTypeData.codeDesc;

        var rs = await store.dispatch('getAreaList', {'langcode': 'B5'})
        var area1 = (rs || []).find((d)=>d.codeid==jobDetail.arealevel1id)
        var rs2 = await store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': jobDetail.arealevel1id})
        var children = rs2 || []
        var area2 = children.find((d)=>d.codeid==jobDetail.arealevel2id)

        const title = `招聘：${jobDetail.employmentType || ''} ${jobDetail.title}（${area1.codedesc || ''} ${area2.codedesc || ''}）- ${jobDetail.company||''} — 香港中醫在綫`;
        if(jobDetail.title) {
          document.title = title
        }
        const keywords = `中醫師招聘,中醫招聘,中醫職位空缺,香港中醫在綫,中醫診所：${jobDetail.company}, 招聘：${jobDetail.employmentType || ''} ${jobDetail.title}（${area1.codedesc || ''} ${area2.codedesc || ''}）（${jobDetail.number}名）,中醫招聘, 診所招聘`;
        const description = `中醫診所：${jobDetail.company}，招聘：${jobDetail.title || '--'}，電話：${jobDetail.contact || '--'}，地址：${jobDetail.address || '--'}`;
        appendMeta(keywords, description)
        
        //设置google job
        var html = {
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          "description": description,
          "mainEntityOfPage": to.fullPath,
          "title": jobDetail.title,
          "validThrough": jobDetail.expiredate,
          "datePosted": (new Date()).toISOString().substring(0,10),
          "employmentType": "FULL_TIME",
          "hiringOrganization": {
              "@type": "Organization",
              "logo": jobDetail.cliniclogo,
              "name": jobDetail.company
          },
          "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": "HKD",
              "value": {
                  "@type": "QuantitativeValue",
                  "unitText": "MONTH",
                  "minValue": -1,
                  "maxValue": -1
              }
          },
          "jobLocation": [
              {
                  "@type": "Place",
                  "address": {
                      "@type": "PostalAddress",
                      "addressCountry": "HK",
                      "postalCode": "00000",
                      "streetAddress": jobDetail.address || `${area1.codedesc} ${area2.codedesc}`,
                      "addressLocality": area2.codedesc,
                      "addressRegion": area1.codedesc
                  },
                  "geo": {
                      "@type": "GeoCoordinates",
                      "latitude": jobDetail.lat,
                      "longitude": jobDetail.lng
                  }
              }
          ]
        };
        var element = document.createElement("script")
        element.setAttribute('data-n-head', 'ssr')
        element.setAttribute('data-hid', 'job-posting')
        element.setAttribute('type', 'application/ld+json')
        element.innerText = JSON.stringify(html)
        document.head.appendChild(element)
        setTimeout(() => {
          next()
        }, 550)
      } else {
        router.push('/job/list')
        next(false)
      }
    } else {
      if (to.meta.title) {
        document.title = to.meta.title
      }
      if(to.path.startsWith('/doctor/list') && to.query.ClinicID) {
        //诊所详情
        router.push(`/hospital/detail/${to.query.ClinicID}`)
      } else {
        // 必须写next()哦，不然你的页面就会白白的，而且不报错，俗称"代码下毒"
        next()
      }
    }
  });

export default router;
