import Vue from 'vue'
import App from './MobileApp'
import router from './router/mobile.js'
import store from './store'
import i18n from './locale'
import filters from './filters'
import myToast from './common/myToast'
import {RadioGroup, Cell, Radio, Popup, Overlay, Toast, Dialog, DatetimePicker, PullRefresh, Picker } from 'vant';
import '../node_modules/viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import constants from './Constants'

import {
  Swiper as SwiperClass,
  Pagination,   //使用那个组件就在这里面添加
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import 'swiper/swiper-bundle.css'
//这里面对应上面进行添加  
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);
Vue.use(getAwesomeSwiper(SwiperClass));

Vue.use(Popup);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(DatetimePicker)
Vue.use(PullRefresh)
Vue.use(Picker)
Vue.use(Overlay)
Vue.use(Viewer)
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.prototype.constants = constants;
Vue.config.productionTip = false
Vue.prototype._toast = myToast

// 如果是非线上环境，加载 VConsole（移动端适用）
// if (process.env.NODE_ENV !== 'production') {
//     var VConsole = require('vconsole/dist/vconsole.min.js');
//     var vConsole = new VConsole();
// }

// 全局过滤器
Object.keys(filters).forEach(filterName => {
  Vue.filter(filterName, filters[filterName])
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app')