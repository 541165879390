<template>
	<div id="app">
		<router-view/>
		<div class="scroll-to-top" v-if="showScrollToTop" @click="toTop"></div>
	</div>
</template>

<script>
	export default {
		name: "App",
		data: function(){
			return {
				showScrollToTop: false
			}
		},
		methods: {
            toTop: function(){
                window.scrollTo(0, 0)
            },
            handleScroll (event) {
                if(this.showScrollToTop) {
                    if(window.pageYOffset <= 10) {
                        this.showScrollToTop = false
                    } 
                } else {
                    if(window.pageYOffset > 10) {
                        this.showScrollToTop = true
                    }
                }
            }
        },
        created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
	};
</script>

<style lang="scss">
	@import "./style/common.scss";

    .swiper-pagination > .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background-color: #aca9a9;
        margin-right: 10px;
        
    }
    .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #36C4D0;
    }
	
	html {
		background-color: #ededed;
	}

	body {
		min-width: unset;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		font-family: 'PingFang-SC-Heavy', 'Microsoft YaHei', sans-serif, 'Helvetica', 'Arial';
	}
   
    #app {
        // font-size: $font-size-base;
        // color: $color-common;
		height: 100%;
    }

	.van-overlay {
		background-color: rgba(0,0,0,.5);
	}
	
	/* 回到顶部  start */
    .scroll-to-top {
        position: fixed;
        right: 40px;
        bottom: 100px;
        width: 50px;
        height: 50px;
        z-index: 999;
        padding: 10px;
        background-color: #ccc;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(./assets/imgs/mobile/img_top@2x.png);
        box-shadow: 2px 2px 5px #ccc;
        cursor: pointer;
    }
    /* 回到顶部  end */
</style>
